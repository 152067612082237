
import DatePicker from "@/components/utils/DatePicker.vue";
import ImageSelector from "@/components/utils/PhotoSelect.vue";
import ValidationMixin from "@/mixins/validation";
import {
  AddDealerPayload,
  Dealer,
  DealerTypes,
} from "@/store/modules/dealer/dealer.types";
import { LocationInfo } from "@/types/types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import {
  BaseDistrict,
  County,
  District,
  districts,
  getDistrict,
  Parish,
  SubCounty,
  Village,
} from "akwap";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";

import { namespace } from "vuex-class";

const agroDealerStore = namespace("Dealer");

@Component({
  components: { ImageSelector, DatePicker },
})
export default class AddAgroDealer extends Mixins(ValidationMixin) {
  @agroDealerStore.State("add")
  public addAgroDealer!: DefaultState;

  @agroDealerStore.Mutation(DealerTypes.SET_ADD_DEALER_DIALOG)
  public setAddAgroDealer!: (addDealer: boolean) => void;

  @agroDealerStore.Action(DealerTypes.ADD_DEALER)
  public saveAgroDealer!: (dealer: AddDealerPayload) => Promise<void>;

  public ugandaDistricts: BaseDistrict[] = districts;

  public isValid = false;
  public userDetailsValid = false;
  public cardsValid = false;
  public locationValid = false;

  public addDealerStep = 1;

  // Is Agro dealer registered
  public isRegistered = false;

  public agroDealer: Dealer = {
    first_name: "",
    last_name: "",
    phone_no: "",
    dob: "",
    card: {
      type: "national-id",
    },
    gender: "M",
    location: {
      district: "",
      county: "",
      sub_county: "",
      parish: "",
      village: "",
    },
  };

  // Images
  public profile = require("@/assets/images/default-image.png");
  public idFront = require("@/assets/images/front.png");
  public idBack = require("@/assets/images/back.png");

  public profileValid = true;
  public idFrontValid = true;
  public idBackValid = true;

  @Ref("profile") public profileRef!: ImageSelector;
  @Ref("idFront") public idFrontRef!: ImageSelector;
  @Ref("idBack") public idBackRef!: ImageSelector;

  @Ref("form") public formRef!: Validator;
  @Ref("userDetailsForm") public userDetailsFormRef!: Validator;
  @Ref("cardsForm") public cardsFormRef!: Validator;
  @Ref("locationForm") public locationFormRef!: Validator;

  closeAddAgroDealerDialog(): void {
    this.setAddAgroDealer(false);
  }

  async continueFromProfilePhoto(): Promise<void> {
    this.agroDealer.photo = await this.profileRef.crop();
    this.profileValid = !!this.agroDealer.photo;

    if (this.profileValid) {
      this.addDealerStep = 2;
    }
  }

  async continueFromUserDetails(): Promise<void> {
    await this.userDetailsFormRef.validate();

    if (this.userDetailsValid) {
      this.addDealerStep = 3;
    }
  }

  async continueFromCards(): Promise<void> {
    await this.cardsFormRef.validate();

    this.agroDealer.card.back = await this.idBackRef.crop();
    this.agroDealer.card.front = await this.idFrontRef.crop();

    this.idBackValid = !!this.agroDealer.card.back;
    this.idFrontValid = !!this.agroDealer.card.front;

    if (this.cardsValid && this.idBackValid && this.idFrontValid) {
      this.addDealerStep = 4;
    }
  }

  async saveAgroDealerToDb(): Promise<void> {
    await this.locationFormRef.validate();

    if (this.locationValid) {
      const addDealerPayload: AddDealerPayload = {
        first_name: this.agroDealer.first_name,
        last_name: this.agroDealer.last_name,
        other_names: this.agroDealer.other_names,
        phone_no: "+256" + this.agroDealer.phone_no,
        dob: this.agroDealer.dob,
        photo: this.agroDealer.photo ?? "",
        card: {
          type: this.agroDealer.card.type,
          no: this.agroDealer.card.no ?? "",
          front: this.agroDealer.card.front ?? "",
          back: this.agroDealer.card.back ?? "",
        },
        gender: this.agroDealer.gender,
        village: this.location.village?.id ?? -1,
      };

      await this.saveAgroDealer(addDealerPayload);
    }
  }

  // Location Parameters
  private districtInfo?: District;
  // Location
  public location: LocationInfo = {};
  public dealerDistrict?: District;
  public counties: County[] = [];
  public subCounties: SubCounty[] = [];
  public parishes: Parish[] = [];
  public villages: Village[] = [];

  @Watch("location.district")
  onDistrictChanged(value?: BaseDistrict): void {
    this.districtInfo = getDistrict(value?.name ?? "");
    if (this.districtInfo) {
      this.counties = this.districtInfo.counties;
    }
  }

  @Watch("location.county")
  onCountyChanged(value?: County): void {
    const county = this.counties.find((county) => county.id == value?.id);
    if (county) {
      this.subCounties = county.sub_counties;
    }
  }

  @Watch("location.sub_county")
  onSubCountyChanged(value?: SubCounty): void {
    const subCounty = this.subCounties.find(
      (subCounty) => subCounty.id == value?.id
    );
    if (subCounty) {
      this.parishes = subCounty.parishes;
    }
  }

  @Watch("location.parish")
  onParishChanged(value?: Parish): void {
    const parish = this.parishes.find((parish) => parish.id == value?.id);
    if (parish) {
      this.villages = parish.villages;
    }
  }
}
